import styled from "styled-components";
import { black, white } from "theme/colors";

export const WorkC = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
  width: 100%;
  cursor: pointer;
  .work_image {
    min-height: 400px;
    .gatsby-image-wrapper {
      width: 100% !important;
      height: 400px !important;
      img {
        width: 100%;
        object-position: center center;
        object-fit: cover !important;
        transition: opacity 500ms ease 0s, transform 2s !important;
        transform: scale(1);
      }
    }
  }
  .work_info {
    h3 {
      margin-top: 10px;
      position: relative;
      z-index: 2;
      background-color: ${white.default};
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      li {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          text-transform: uppercase;
          font-size: 11px;
        }
        span {
          background-color: ${black.default};
          margin: 0 5px;
          position: relative;
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
        }
      }
    }
  }

  &:hover {
    .gatsby-image-wrapper {
      img {
        transform: scale(1.1);
      }
    }
  }
  @media (min-width: 769px) {
    width: 45%;
  }
`;
