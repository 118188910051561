import styled from "styled-components";
import { black, white } from "../../theme/colors";

export const WorksContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .works {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
  }
`;

export const Work = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
  width: 100%;
  .work_info {
    h3 {
      margin-top: 10px;
      position: relative;
      z-index: 2;
      background-color: ${white.default};
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      li {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          text-transform: uppercase;
          font-size: 11px;
        }
        span {
          background-color: ${black.default};
          margin: 0 5px;
          position: relative;
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
        }
      }
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 400px;
    img {
      width: 100%;
      object-fit: cover !important;
      transition: opacity 500ms ease 0s, transform 2s !important;
      transform: scale(1);
    }
  }
  &:hover {
    .gatsby-image-wrapper {
      img {
        transform: scale(1.1);
      }
    }
  }
  @media (min-width: 769px) {
    width: 45%;
  }
`;
